<template>
  <main role="main">
    <section class="section section-page-header">
      <div class="container m-container-only-s">
        <h1>{{ $t("upgrade-seat.info_title") }}</h1>
      </div>
    </section>

    <section class="section section-default section-seats">
      <div class="container m-container-only-s">
        <i class="help-info" @click="infoPopup = !infoPopup"></i>
        <div class="columns plane-b737-800-v1">
          <div class="map-column">
            <div class="seats-map">
              <div
                class="seats-row "
                :class="row.class"
                :key="index"
                v-for="(row, index) in modifySeats"
              >
                <div v-if="index === 0" class="seats-column"></div>
                <div
                  class="seats-column"
                  :key="idx"
                  v-for="(seats, idx) in row.seats"
                >
                  <div
                    :key="ix"
                    v-for="(seat, ix) in seats"
                    class="seat"
                    @click="checkUpgradeSeat(seat, seats)"
                    :class="[
                      seat.seatAvailability === 0 ||
                      seat.nameHash ||
                      seat.disabled
                        ? 'unavailable'
                        : '',
                      seat.class,
                      seat.seatNumber === parentSelected ? 'selected' : '',
                      user.seat === seat.seatNumber ? 'active' : ''
                    ]"
                  >
                    <i></i>
                    <b></b>
                    <div class="label">{{ seat.seatNumber }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <info
            :selected-column="parentSelectedColumn"
            :selected="parentSelected"
            @selected="parentSelected = false"
            :type="type"
            :info="infoPopup"
            @closeInfo="infoPopup = false"
            @update="$emit('update', $event)"
          ></info>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import Info from "@/components/planes/Info";
import planesReplace from "@/mixins/planesReplace";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    Info
  },
  props: ["change", "seats", "type"],
  mixins: [planesReplace],
  name: "B737V2",
  data: () => {
    return {
      spacesConstant: { 15: true, 16: true },
      parentSelectedColumn: [],
      parentSelected: "",
      reload: true
    };
  },
  watch: {
    parentSelected(value) {
      if (value) {
        document.documentElement.classList.add("seat-map-popup-opened");
      } else {
        document.documentElement.classList.remove("seat-map-popup-opened");
      }
    }
  },
  beforeDestroy() {
    document.documentElement.classList.remove("seat-map-layout");

    document.documentElement.classList.remove("seat-map-popup-opened");
  },
  beforeCreate() {
    document.documentElement.classList.add("seat-map-layout");
  },
  computed: {
    ...mapState("user", {
      user: state => state.user
    }),
    ...mapState("order", {
      layout: state => state.layout
    }),
    getLayoutUpgrade() {
      return this.layout.find(item => item.category === "upgradePlace");
    },
    modifySeats: {
      get() {
        let rowObject = {};
        if (this.$store.state.flight.reload) {
          rowObject = {};
        }

        const newArray = [];
        const ms = this.seats.map(item => {
          let className = this.replaces[item.class];
          if (!item.features) {
            item.features = [];
          }
          item.features.sort(function(a, b) {
            if (a < b) {
              return 1;
            }
            if (a > b) {
              return -1;
            }
            return 0;
          });
          for (let i = 0; i < item.features.length; i++) {
            className = className + "" + this.features[item.features[i]];
          }
          if (item.disabled) {
            return {
              row: parseInt(item.seatNumber),
              seat: {
                ...item,
                asd: item.disabled,
                class: className
              }
            };
          } else {
            return {
              row: parseInt(item.seatNumber),
              seat: {
                ...item,
                disabled: item.disabled,
                class: className
              }
            };
          }
        });
        for (let i = 0; i < this.seats.length; i++) {
          let row = parseInt(this.seats[i].seatNumber);
          if (rowObject.row !== row) {
            rowObject = {};
            rowObject.row = row;
            rowObject.class =
              this.seats[i].class === "c" ? "business-row" : "econom-row";
            if (
              rowObject.class === "econom-row" &&
              newArray[newArray.length - 1] &&
              newArray[newArray.length - 1].class === "business-row"
            ) {
              rowObject.class = "econom-row after-business";
            }
            if (this.spacesConstant[row]) {
              rowObject.class += " pass";
            }
            rowObject.seats = this.chunkArray(
              ms
                .filter(it => it.row === row)
                .map(itm => {
                  return itm.seat;
                }),
              this.seats[i].class === "c" ? 2 : 3
            );
            newArray.push(rowObject);
          }
        }
        return newArray;
      }
    }
  },
  methods: {
    ...mapActions("order", {
      cancelOrder: "cancelOrder"
    }),
    checkUpgradeSeat(seat, seats) {
      if (
        seat.seatAvailability === 0 ||
        seat.nameHash ||
        seat.seatNumber === this.user.seat ||
        seat.disabled
      ) {
        return false;
      }
      this.cancelOrder({ id: this.getLayoutUpgrade.id }).then(() => {
        this.parentSelectedColumn = seats;
        this.parentSelected = seat.seatNumber;
      });
    },
    chunkArray(array, chunk) {
      const newArray = [];
      for (let i = 0; i < array.length; i += chunk) {
        newArray.push(array.slice(i, i + chunk));
      }
      return newArray;
    }
  }
};
</script>

<style scoped>
.off {
  opacity: 0.4;
}
</style>
